<template>
    <b-modal size="lg"
             centered
             hide-header
             hide-footer
             content-class="rounded-xl border-0 shadow"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <div class="title position-sticky d-flex justify-content-between align-items-center mb-2">
            <h1 class="h3 font-noto-serif-tc font-weight-bolder">
                Activity Categories
            </h1>
            <div>
                <button class="btn btn-lg btn-outline-dark border-0 mr-1"
                        v-on:click.prevent.stop="hideModal">
                    <font-awesome-icon icon="times"></font-awesome-icon>
                </button>
            </div>
        </div>
        <ul class="list-group shadow-sm">
            <category-item v-for="(category, ind) in categories"
                           v-bind:key="category.id"
                           v-bind:index="ind"
                           v-bind:categories="categories"
                           v-bind:category="category">
            </category-item>
        </ul>
        <div class="actions position-sticky py-2">
            <ul class="list-group shadow-sm">
                <li class="list-group-item bg-light shadow-sm p-2">
                    <form class="d-flex flex-nowrap"
                          v-on:submit.prevent.stop="addCategory">
                        <div class="d-flex flex-grow-1 w-100 pr-2">
                            <b-form-input class="input-color mr-1"
                                          size="sm"
                                          type="color"
                                          required
                                          v-model="newCategoryColor">
                            </b-form-input>
                            <b-form-input class="flex-grow-1"
                                          size="sm"
                                          type="text"
                                          required
                                          v-model="newCategoryName">
                            </b-form-input>
                        </div>
                        <button class="btn btn-sm btn-success text-nowrap"
                                type="submit"
                                v-bind:class="{ 'btn-loading': isLoading, }">
                            ✨ Create
                        </button>
                    </form>
                </li>
            </ul>
        </div>
    </b-modal>
</template>

<script>
import { BModal, BFormInput, } from "bootstrap-vue";
export default {
    name: "ModalActivityCategories",
    components: {
        BModal, BFormInput,
        CategoryItem: () => import("./ModalActivityCategories/CategoryItem"),
    },
    props: {
        "value": {
            type: Boolean,
            default: false,
        },

    },
    data () {
        return {
            isLoading: false,

            newCategoryColor: "",
            newCategoryName: "",
        };
    },
    computed: {
        categories () {
            return this.$store.getters["category/itemsByTypeEvent"]
                .slice(0)
                .sort((a, b) => a.order - b.order);
        },
    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },
        reset () {
            this.newCategoryColor = "";
            this.newCategoryName = "";
        },
        async addCategory () {
            this.isLoading = true;
            const { newCategoryColor, newCategoryName, } = this;
            try {
                await this.$store.dispatch(
                    "category/addCategory",
                    {
                        "type": "activity",
                        "order": this.categories.length,
                        "name": newCategoryName,
                        "color": newCategoryColor,
                    }
                );
            } catch (e) {
                console.error(e);
                this.isLoading = false;
                return;
            }
            this.reset();
            this.isLoading = false;
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    top: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2.5px);
    z-index: 99;
}

.actions {
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2.5px);
    z-index: 99;
}

.input-color {
    max-width: 70px;
}
</style>
